import Image from "next/image";

export default function BrandCommentItem({comment, className}: BrandCommentItemProps) {
	return (
		<div className={`text-white text-2xl flex flex-col gap-5 xl:w-[880px] h-[197px] text-start justify-between pt-8 ${comment.className} ${className}`}>
			<p>{comment.comment}</p>
			<div className={"flex justify-end gap-3 items-center"}>
				<div>
					<p className={"font-semibold text-base text-white text-end"}>{comment.author}</p>
					<p className={"font-light text-xs text-white"}>{comment.authorDescription}</p>
				</div>
				<Image src={comment.authorImage} alt={"author image"} width={60} height={60} className={"rounded-full"}/>
			</div>
		</div>
	)
}

export interface BrandComment {
	comment: string,
	author: string,
	authorDescription: string,
	authorImage: string,
	brandImage: string,
	brandClassName?: string,
	className?: string,
}

export interface BrandCommentItemProps {
	comment: BrandComment
	className?: string
}