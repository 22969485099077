export default function HeroNews({title}: HeroNewsProps){
	return (
		<div className={"w-full h-[50px] font-medium text-base bg-white/20 flex items-center justify-center text-center text-white"}>
			<p>{title}</p>
		</div>
	)
}

interface HeroNewsProps {
	title?: string,
}