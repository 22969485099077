import Image from "next/image";
import {GenericContainer} from "@/components/generic/GenericContainer";
import GenericBlueButton from "@/components/generic/GenericBlueButton";
import GenericLinkButton from "@/components/generic/GenericLinkButton";
import React from "react";

export default function FeatureComponent({
	                                         image, title, subtitle, description, button1Title, button2Title, button2Href,
	                                         button1Href, reverse, containerClassName, key, customChildren
                                         }: FeatureComponentProps) {

	return (
		<GenericContainer
			className={`min-h-max flex text-center xl:text-start ${reverse ? "flex-col xl:flex-row-reverse" : "flex-col xl:flex-row"} justify-center items-center gap-1 xl:gap-0 gap-x-12 ${reverse ? "bg-company-video-bg bg-no-repeat bg-100% " : "bg-white"} ${containerClassName}`}>
			<div className={"flex flex-col max-w-[431px] justify-center items-center gap-3 xl:gap-0 xl:items-start"}>
				<div className={"xl:mb-5"}>
					{title && <p className={"text-2xl xl:text-4lg pb-0 xl:pb-1 font-semibold text-text_dark"}>{title}</p>}
					{subtitle && <p className={"text-xl xl:text-2xl font-light text-text_dark"}>{subtitle}</p>}
					{description && <p className={"text-text_light font-light text-sm xl:text-base mt-3 xl:mt-5"}>{description}</p>}
				</div>
				<div className={"flex gap-x-4"}>
					{button1Title && button1Href &&
						<GenericBlueButton href={button1Href} title={button1Title} className={"text-base font-medium"}/>}
					{button2Title && button2Href && <GenericLinkButton href={button2Href} title={button2Title}/>}
				</div>
				{customChildren && customChildren}
			</div>
			{image &&
				<div className={"xl:!w-[401px] xl:h-[401px] w-72 flex items-center"}>
					<Image src={image} alt={title || "Feature Image"} width={1000} height={401}/>
				</div>}
		</GenericContainer>
	)
}

export interface FeatureComponentProps {
	image?: string,
	title?: string,
	icon?: string,
	subtitle?: string,
	description?: string,
	button1Title?: string,
	button1Href?: string,
	button2Title?: string,
	button2Href?: string,
	reverse?: boolean,
	containerClassName?: string,
	key?: string | number,
	customChildren?: React.ReactNode
}
