'use client'
import {useEffect, useState} from "react";
import {BrandCommentsComponent, BrandCommentsComponentProps} from "@/components/brand-comments/BrandCommentsComponent";
import {MobileBrandCommentsComponent} from "@/components/brand-comments/MobileBrandCommentsComponent";

export function BrandCommentsContainer({comments}: BrandCommentsComponentProps) {
	const [windowWidth, setWindowWidth] = useState(0);

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};
		setWindowWidth(window.innerWidth)
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		windowWidth >= 768 ?
			<BrandCommentsComponent comments={comments}/> :
			<MobileBrandCommentsComponent comments={comments}/>

	)
}