import Icon from "@/components/icon/Icon";

export default function GenericLinkButton({href, title, icon, className, disableIcon}: GenericButtonProps) {
	return (
		<a className={`text-base font-medium gap-1 inline-flex text-formica items-center ${className} hover:underline`} href={href}>
			{title || "Learn more"}
			{//TODO burasındaki icon ismi değiştirilecek
				}
				{
					!disableIcon &&
					<Icon icon={icon || "learn_more_icon" } width={20} className={"fill-formica"} />
				}
		</a>
	)
}

interface GenericButtonProps {
	href?: string,
	title?: string,
	icon?: string,
	className?: string
	disableIcon?: boolean
}
