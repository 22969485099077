import { GenericContainer } from "@/components/generic/GenericContainer";
import { GenericTitle } from "@/components/generic/GenericTitle";
import FirstGlanceItem from "@/components/first-glance/FirstGlanceItem";
import { useTranslations } from "next-intl";

export function FirstGlanceComponent() {
	const t = useTranslations('firstGlance');
	const keys = ['items.0', 'items.1', 'items.2', 'items.3', 'items.4'] as const;

	return (
		<GenericContainer className={"bg-first-glance-bg bg-no-repeat bg-100% justify-center text-center grid gap-10"}>
			<GenericTitle title={t("title")} />
			<GenericContainer className={"md:!grid-cols-5 grid !px-0 !py-0 max-w-container"}>
				{keys.map((key, index) => (
					<FirstGlanceItem
						key={key}
						title={t(`${key}.title`)}
						description={t(`${key}.description`)}
						icon={`glance_${index + 1}`}
						showLine={index < keys.length - 1}
					/>
				))}
			</GenericContainer>
		</GenericContainer>
	);
}
