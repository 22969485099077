import {GenericContainer} from "@/components/generic/GenericContainer";
import {GenericTitle} from "@/components/generic/GenericTitle";
import Icon from "@/components/icon/Icon";
import {useTranslations} from "next-intl";

export function HepsiburadaComponent() {
	const t = useTranslations('');

	return (
		<GenericContainer className={"bg-company-video-bg bg-no-repeat bg-100% justify-center text-center grid gap-[52px]"}>
			<GenericTitle title={t("hepsiburada.title")} description={t("hepsiburada.description")}/>
			<GenericContainer className={"!gap-5 xl:!gap-12 !px-0 !py-0 flex xl:flex-row flex-col justify-center items-center max-w-container"}>
				<div className={"flex flex-col justify-center"}>
					<iframe src="https://www.youtube-nocookie.com/embed/t-BnwIkQrw4?si=haorQ-_Arl8-xcuo&amp;controls=0"
					        title="Formica Customer Story - @hepsiburada" frameBorder="0"
					        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					        className={"w-[343px] h-[195px] sm:w-[393px] sm:h-[221px] md:w-[692px] md:h-[389px] xl:w-[393px] xl:h-[221px] rounded-2xl"}
					        allowFullScreen/>
				</div>
				<div className={"grid gap-y-3"}>
					<div className={"text-text_dark font-light text-base bg-white rounded-xl text-left p-4"}>
						<Icon icon={"tirnak_isareti"} width={14}/>
						<p>
							{t("hepsiburada.quote")}
						</p>
					</div>
					<div className={"text-text_light font-light text-xs bg-white rounded-xl text-left p-4 flex flex-row justify-between"}>
							<div>
							<p className={"font-semibold text-text_dark text-sm"}>
								{t("hepsiburada.author")}
							</p>
							<p>
								{t("hepsiburada.authorDescription")}
							</p>
							</div>
						<div className={"grid grid-cols-5 gap-x-1 text-orange-300"}>
							<Icon icon={"yildiz_icon"} width={16} fill={"orange"}/>
							<Icon icon={"yildiz_icon"} width={16} fill={"orange"}/>
							<Icon icon={"yildiz_icon"} width={16} fill={"orange"}/>
							<Icon icon={"yildiz_icon"} width={16} fill={"orange"}/>
							<Icon icon={"yildiz_icon"} width={16} fill={"orange"}/>
						</div>
					</div>
				</div>
			</GenericContainer>
		</GenericContainer>
	)
}
