import Image from "next/image";
import {BrandCommentItemProps} from "@/components/brand-comments/BrandCommentItem";

export default function MobileBrandCommentItem({comment, className}: BrandCommentItemProps) {
	return (
		<div
			className={`text-white text-sm flex flex-col gap-5 xl:w-[880px] text-center italic items-center justify-between ${comment.className} ${className}`}>
			<Image key={comment.author} src={comment.brandImage} alt={comment.author}
			       width={300} height={0} className={`h-5 ${comment.brandClassName} h-full w-[114px]`}/>
			<p className={"h-28"}>{comment.comment}</p>
			<div className={"flex flex-col justify-center gap-1 items-center"}>
				<Image src={comment.authorImage} alt={"author image"} width={40} height={40} className={"rounded-full"}/>
				<p className={"font-semibold text-sm text-white text-end"}>{comment.author}</p>
				<p className={"font-light text-xs text-text_normal"}>{comment.authorDescription}</p>
			</div>
		</div>
	)
}