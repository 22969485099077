import Image from "next/image";

export default function HeroMarqueeWall({brands}: HeroMarqueeWallProps){

	return (
		<div className="relative flex overflow-x-hidden max-w-[100vw] w-[343px] md:w-[696px] xl:w-[1080px] flex-row">
			<div className="py-12 animate-marquee whitespace-nowrap flex flex-row">
				{brands.map((brand, index) => (
					<span key={index} className="text-4xl mx-12 min-w-max"><Image src={`/brands/marquee/${brand}_logo.png`} loading={"eager"} alt={brand} width={0} height={0} sizes={"100vw"} className={"h-5 md:h-6 xl:h-8 w-auto"}/></span>
				))}
			</div>

			<div className="absolute top-0 py-12 animate-marquee2 whitespace-nowrap flex flex-row">
				{brands.map((brand, index) => (
					<span key={index} className="text-4xl mx-12 min-w-max"><Image src={`/brands/marquee/${brand}_logo.png`} loading={"eager"} alt={brand} width={0} height={0} sizes={"100vw"} className={"h-5 md:h-6 xl:h-8 w-auto"}/></span>
				))}
			</div>
		</div>

	)

}

interface HeroMarqueeWallProps {
	brands: brandName[];
}

export type brandName = "aköde" | "anadolubank" | "dgpays" | "flogroup" | "hepsiburada" | "hepsipay" | "moka" | "nurolbank" | "oyak_yatırım" | "pazarama" | "unitedpayment" | "moneypay" | "papara" | "sipay" | "papel" | "moneytolia" | "colendi" | "goldenglobal" | "hayatfinans" | "platformodeme" | "bpn";
