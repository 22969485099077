'use client'
import {GenericContainer} from "@/components/generic/GenericContainer";
import {BrandComment} from "@/components/brand-comments/BrandCommentItem";
import {BrandCommentsComponentProps} from "@/components/brand-comments/BrandCommentsComponent";
import { Navigation, Pagination, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import MobileBrandCommentItem from "@/components/brand-comments/MobileBrandCommentItem";

export function MobileBrandCommentsComponent({comments}: BrandCommentsComponentProps) {

	return (
		<GenericContainer className={"bg-comments-mobile-bg bg-no-repeat bg-100% w-full"}>
			<Swiper
				modules={[Navigation, Pagination, A11y, Autoplay]}
				autoplay={{
					delay: 10000
				}}
				spaceBetween={50}
				slidesPerView={1}
				pagination={{ clickable: true, bulletActiveClass: "!bg-white"}}
			>
				{
					comments.map((comment: BrandComment) => {
						return (
							<SwiperSlide key={comment.author} className={"mb-12"}>
								<MobileBrandCommentItem comment={comment}/>
							</SwiperSlide>
						)
					})
				}
			</Swiper>
		</GenericContainer>
	)
}