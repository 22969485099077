'use client'
import {GenericContainer} from "@/components/generic/GenericContainer";
import BrandCommentItem, {BrandComment, BrandCommentItemProps,} from "@/components/brand-comments/BrandCommentItem";
import Image from "next/image";
import {useEffect, useState} from "react";

export function BrandCommentsComponent({comments}: BrandCommentsComponentProps) {
	const [selectedComment, setSelectedComment] = useState<BrandComment>(comments[0])

	useEffect(() => {
		setSelectedComment(comments[0])
	}, [comments]);
	const onClick = (comment: BrandComment) => {
		setSelectedComment(comment)
	}

	return (
		<GenericContainer className={"bg-comments-bg bg-no-repeat bg-100% !h-[442px] align-middle justify-center items-center flex flex-col"}>
			<div className={"text-center gap-7 flex flex-col h-[88px]"}>
				<div className={"h-[1px] bg-[url('/Line.png')] w-full"}/>
				<div className={"gap-8 flex justify-center"}>
					{
						comments.map((comment: BrandComment) => {
							return (
								<button onClick={() => onClick(comment)}
								        key={comment.author}
								        className={`${selectedComment == comment ? "opacity-100" : "opacity-20"} min-w-fit`}>
									<Image key={comment.author} src={comment.brandImage} alt={comment.author}
									       width={400} height={200} className={`h-5 w-full aspect-auto ${comment.brandClassName}`}/>
								</button>
							)
						})
					}
				</div>
				<div className={"h-[1px] bg-[url('/Line.png')] w-full"}/>
			</div>
			<BrandCommentItem comment={selectedComment}/>
		</GenericContainer>
	)
}

export interface BrandCommentsComponentProps {
	comments: BrandComment[],
}
