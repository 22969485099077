export function GenericTitle({
	                             title,
	                             description,
	                             containerClassName,
	                             titleClassName,
	                             descriptionClassName,
	                             description2,
	                             description2ClassName
                             }: GenericHeaderProps) {

	return (
		<div className={`w-full gap-1 xl:gap-3 grid ${containerClassName}`}>
			{
				title && <p className={`text-formica font-normal text-sm md:text-base xl:text-lg ${titleClassName}`}>
					{title}
				</p>
			}
			{
				description && <p className={`text-text_dark font-medium text-2xl xl:text-3xl ${descriptionClassName}`}>
					{description}
				</p>
			}
			{
				description2 && <p className={`text-text_light font-normal text-base  ${description2ClassName}`}>
					{description2}
				</p>
			}
		</div>
	)

}

interface GenericHeaderProps {
	title?: string,
	description?: string,
	description2?: string,
	containerClassName?: string
	titleClassName?: string
	descriptionClassName?: string
	description2ClassName?: string
}
