import Icon from "@/components/icon/Icon";
import Image from "next/image";

export default function FirstGlanceItem({
	                                        title,
	                                        description,
	                                        icon,
	                                        showLine
                                        }: FirstGlanceItemProps
) {

	return (
		<div className="flex-col gap-4 w-full h-full items-center text-center flex justify-around md:justify-start text-formica">
			<Icon icon={icon} width={60} className={"xl:w-[60px] w-10 fill-formica"}/>
			<div className={"gap-4"}>
				<p className={"text-2xl xl:text-4xl font-semibold text-white"}>
					{title}
				</p>
				<p className={"text-sm xl:text-base font-normal text-white/80 whitespace-pre-wrap xl:whitespace-normal"}>
					{description}
				</p>
				{showLine != false &&
					<div className={"h-20 justify-center flex md:h-0"}>
						<Image src={"/FooterLine.svg"} alt={"Footer Line"} width={0} height={1}
						       className={"xl:hidden w-full max-w-container"}/>
					</div>
				}
			</div>
		</div>
	)

}

export interface FirstGlanceItemProps {
	title: string,
	description: string,
	icon: string,
	showLine?: boolean
}

