import GenericLinkButton from "@/components/generic/GenericLinkButton";
import Image from "next/image";

export default function SolutionItem({
	                                     title,
	                                     description,
	                                     image,
	                                     href,
	                                     buttonText
                                     }: SolutionItemProps
) {

	return (
		<div className="flex-col gap-3 xl:gap-4 w-full h-full items-center text-center flex justify-around">
			<Image src={image} alt={title} width={80} height={80}/>
			<div className={"gap-1 xl:gap-2 flex items-center flex-col"}>
			<p className={"text-base font-normal text-text_dark"}>
				{title}
			</p>
			<p className={"text-sm font-light text-text_light"}>
				{description}
			</p>
			</div>
			<nav>
				<GenericLinkButton href={href} title={buttonText} icon={"learn_more_icon"}/>
			</nav>
		</div>
	)

}

export interface SolutionItemProps {
	title: string,
	description: string,
	image: string,
	href: string,
	buttonText?: string
}

