'use client'
import React, {useEffect, useRef, useState} from "react";
import Icon from "@/components/icon/Icon";

export function GenericTabMenu({titles, icons, onChange, initialTab}: GenericTabMenuProps) {
	const [activeTab, setActiveTab] = React.useState(0);
	const [windowWidth, setWindowWidth] = useState(0);

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};
		setWindowWidth(window.innerWidth)
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		if (initialTab) setActiveTab(initialTab);
	}, [initialTab]);

	const changeActiveTab = (index: number) => {
		setActiveTab(index);
		if (onChange) onChange(index);
	}
	return (
		<div className={"flex flex-row gap-1 p-1 rounded-4xl bg-tab_bg border-1/2 border-[#e8e8e8] w-fit"}>
			{titles.map((title, index) => {
				return (
					<div
						onClick={() => changeActiveTab(index)}
						key={index}
						className={`flex flex-col min-w-[60px] justify-center text-center gap-2 py-2 px-4 cursor-pointer ${index == activeTab ? "bg-white shadow-tab-button" : "bg-transparent"} rounded-4xl`}>
						<p className={`flex align-middle items-center justify-center text-sm font-light ${index == activeTab ? "text-text_dark " : "text-text_dark/40"}`}>
							{windowWidth < 768 && icons && icons[index] ? <Icon icon={icons[index] as string} width={16}/> : title}
						</p>
					</div>
				);
			})}
		</div>
	);

}

export interface GenericTabMenuProps {
	titles: string[],
	icons?: (string | undefined)[],
	onChange?: (index: number) => void,
	initialTab?: number
}